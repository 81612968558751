export const words: string[] = [
  "Nascimento",
  "Tempo",
  "Morte",
  "Duração",
  "Passagem",
  "Infância",
  "Juventude",
  "Amor",
  "Família",
  "Dia a dia",
  "Intensidade",
  "Sensação",
  "Vitória",
  "Perigo",
  "Dúvida",
  "Força",
  "Perseverança",
  "Descoberta",
  "Surpresa",
  "Aprendizado",
  "Impacto",
  "Corpo",
  "Passado",
  "Presente",
  "Futuro",
  "Amor",
  "Acúmulo",
  "Memória",
  "Prazer",
  "Humor",
  "Encontro",
  "Exaustão",
  "Mundo",
  "Terra",
  "Casa",
  "Movimento",
  "Aconchego",
  "Atenção",
  "Mãe",
  "Pai",
  "Luz",
  "Espaço",
  "Saúde",
  "Herança",
  "Abraço",
  "Tato",
  "Visão",
  "Paladar",
  "Assombro",
  "Trabalho",
  "Tensão",
  "Permissão",
  "Bagagem",
  "Fugacidade",
  "Dor",
  "Prazer",
  "Contato",
  "Ritmo",
];
